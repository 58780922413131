import React from 'react';

interface IconProps {
  className?: string;
}

export default function MessageIcon({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="20"
      viewBox="0 0 23 20"
      className={className}
    >
      <path
        fill="currentColor"
        d="M16.17 0H6.484A6.48 6.48 0 000 6.484V9.96a6.48 6.48 0 006.484 6.484H8.7c4.35 0 6.43 3.557 6.43 3.557s-.766-3.557 1.532-3.557h-.492c3.584 0 6.511-2.9 6.511-6.484V6.484C22.681 2.9 19.754 0 16.17 0zm-4.843 7.688a1.53 1.53 0 011.532 1.532 1.53 1.53 0 01-1.532 1.532A1.53 1.53 0 019.795 9.22a1.53 1.53 0 011.532-1.532zM7.25 10.725a1.53 1.53 0 01-1.532-1.532A1.53 1.53 0 017.25 7.66a1.53 1.53 0 011.532 1.532 1.53 1.53 0 01-1.532 1.532zm8.208 0a1.53 1.53 0 01-1.532-1.532 1.53 1.53 0 011.532-1.532 1.53 1.53 0 011.532 1.532 1.53 1.53 0 01-1.532 1.532z"
      />
    </svg>
  );
}
