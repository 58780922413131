import React from 'react';

interface IconProps {
  className?: string;
}

export default function SettingsIcon({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 19 20"
      className={className}
    >
      <path
        fill="currentColor"
        d="M16.639 11.225a.22.22 0 00-.117-.059c.058-.35.088-.7.088-1.079 0-.408-.03-.816-.088-1.195.058-.029.088-.058.146-.087a2.791 2.791 0 001.02-3.79 2.791 2.791 0 00-3.79-1.02c-.058.028-.117.087-.175.116-.583-.467-1.224-.817-1.924-1.108V2.77A2.787 2.787 0 009.029 0a2.787 2.787 0 00-2.77 2.77v.233c-.7.262-1.34.641-1.924 1.108-.058-.03-.116-.088-.175-.117-1.311-.758-3.032-.32-3.79 1.02-.758 1.313-.32 3.033 1.02 3.79.06.03.088.059.147.088a7.953 7.953 0 00-.088 1.196c0 .35.03.728.088 1.078-.03.03-.088.03-.117.059a2.791 2.791 0 00-1.02 3.79 2.791 2.791 0 003.79 1.02c.029-.03.058-.03.087-.058a7.714 7.714 0 002.012 1.166v.087A2.787 2.787 0 009.059 20a2.787 2.787 0 002.77-2.77v-.087a8.074 8.074 0 002.01-1.166c.03.029.06.029.088.058 1.312.758 3.032.32 3.79-1.02.7-1.341.234-3.033-1.078-3.79zM9 14.46a4.367 4.367 0 01-4.373-4.373A4.367 4.367 0 019 5.714a4.367 4.367 0 014.373 4.373A4.367 4.367 0 019 14.462z"
      />
    </svg>
  );
}
